<template>
  <section>
    <div class="row lb mb-3 pb-3" :key="index">
      <div class="col-12">
        <b>{{ $t("supplier.contact.contact") }} {{ index + 1 }}</b>
        <b-button
          class="float-right btn-delete"
          @click="deleteContact(index)"
          :disabled="disabled"
        >
          {{ $t("supplier.contact.delete") }}
        </b-button>
      </div>
      <div class="col-lg-6 col-12">
        <label>{{ $t("supplier.contact.name") }}</label>
        <InputText v-model="item.name" :disabled="disabled"/>
      </div>
      <div class="col-lg-6 col-12">
        <label>{{ $t("supplier.contact.role") }}</label>
        <InputText v-model="item.role" :disabled="disabled"/>
      </div>

      <div class="col-lg-6 col-12">
        <label>{{ $t("supplier.contact.email") }}</label>
        <InputText v-model="item.email" :disabled="disabled"/>
      </div>

      <div class="col-lg-6 col-12">
        <label>{{ $t("supplier.contact.phone") }}</label>
        <InputText v-model="item.phone" :disabled="disabled"/>
      </div>

      <div class="col-lg-6 col-12">
        <label>{{ $t("supplier.contact.comment") }}</label>
        <InputComment v-model="item.comment"
          :disabled="disabled"/>
      </div>
    </div>
  </section>
</template>

<script>
import InputText from "@/modules/global/atomComponents/InputText.vue";
import InputComment from "@/modules/global/atomComponents/InputComment.vue";

export default {
  name: "Contact",
  components: {
    InputText,
    InputComment
  },
  props: ["item", "index", "disabled"],
  methods: {
    deleteContact(index) {
      this.$emit("delete", index);
    },
  },
};
</script>

<style>
</style>