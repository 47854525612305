<template>
  <section class="container">
    <div class="row justify-content-end">
      <div class="col-auto">
        <BaseActionButton
          type="button"
          v-on:on-click="setEditable"
          v-if="hasContact"
        >
          <font-awesome-icon :icon="['far', 'edit']" class="icon" />
        </BaseActionButton>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-auto">
        <b-button w-100 @click="addContact" :disabled="disabled"
          >Tilføj kontakt</b-button
        >
      </div>
    </div>
    <b-form @submit.prevent="onSubmit">
      <template v-for="(item, index) in supplier.contacts">
        <contact
          :disabled="disabled"
          :key="index"
          :item="item"
          :index="index"
          v-model="supplier.contacts[index]"
          v-on:delete="deleteContact"
        />
      </template>
      <b-form-group id="signup-emailForm" v-if="hasContact">
        <BaseActionButton
          class="btn-update"
          v-bind:clicked="clicked"
          v-bind:disabled="clicked"
          type="submit"
        >
          {{ $t("global.update") }}
        </BaseActionButton>
      </b-form-group>
    </b-form>
  </section>
</template>

<script>
import { Contact as ContactModel } from "@/models/sub-models/supplier/contact";
import Contact from "@/modules/general/supplier/components/Contact.vue";
export default {
  name: "SupplierContacts",
  components: {
    contact: Contact,
  },
  data() {
    return {
      clicked: false,
      disabled: true,
    };
  },
  async mounted() {
    this.$parent.$emit("updateLoader");
    if (!this.hasContact) {
      this.setEditable();
    }
  },
  methods: {
    setEditable() {
      this.disabled = !this.disabled;
    },
    addContact() {
      this.supplier.contacts.push(new ContactModel({}));
    },
    deleteContact(i) {
      this.supplier.contacts.splice(i, 1);
    },
    async onSubmit() {
      this.clicked = true;
      var payload = this.supplier;
      var id = this.id;
      try {
        var result = await this.$store.dispatch("supplierVuex/update", {
          payload,
          id,
        });
        this.clicked = false;
        this.toast(
          "Success",
          "Pensionselskabet er nu opdateret", true
        );
      } catch (error) {
        this.clicked = false;
        this.toast(
          error.response.data.code,
          error.response.data.message, false
        );
      }
    },
  },
  computed: {
    hasContact() {
      if (this.supplier.contacts) {
        return this.supplier.contacts.length;
      } else return false;
    },
    id() {
      return this.$route.params.supplierId;
    },
    supplier() {
      return this.$store.getters["supplierVuex/supplier"];
    },
  },
};
</script>

<style>
</style>